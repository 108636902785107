/* globals localforage, uuid, define */

define('localforage', [], function() {
  "use strict";

  return {
    'default': localforage
  };
});

define('uuid', [], function() {
  "use strict";

  return {
    'default': uuid
  };
});
